<template>
  <div
    class="beta-banner q-px-md bg-warning-2 text-warning-10 shadow-1 row no-wrap gap-sm"
    :class="[isExpanded ? 'items-start q-py-sm' : 'items-center q-py-xs']"
  >
    <q-icon name="sym_r_info" :size="isExpanded ? '24px' : '16px'" />
    <i18n-t v-if="isExpanded" keypath="gaebViewer.betaBanner" tag="div">
      <template #email>
        <a
          :href="`mailto:support@kinisto.com?subject=${$t(
            'gaebViewer.supportEmailSubject'
          )}`"
          >support@kinisto.com</a
        >
      </template>
    </i18n-t>
    <div v-else>
      {{ $t("gaebViewer.betaBannerShort") }}
    </div>
    <q-space />
    <q-btn
      dense
      flat
      :icon="isExpanded ? 'sym_r_expand_more' : 'sym_r_expand_less'"
      size="sm"
      @click="isExpanded = !isExpanded"
    />
  </div>
</template>

<script setup lang="ts">
import { persistentRef } from "@/utils/persistentRef";

const isExpanded = persistentRef("gaebViewer.betaBannerExpanded", true);
</script>

<style scoped lang="scss">
.beta-banner {
  border-top: 1px solid $grey-3;
  z-index: 1000;
}
</style>
